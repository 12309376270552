import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
import { Pre_demo_img, foodIcon } from "../../../assets/images";
import "./restaurantcategory.css";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import { useLocation, useNavigate } from "react-router-dom";

const groupByShop = (products: any) => {
  return products.reduce((acc: any, product: any) => {
    const { shopName } = product._source;
    if (!acc[shopName]) {
      acc[shopName] = [];
    }
    acc[shopName].push(product._source);
    return acc;
  }, {});
};

const RestaurantCategory = () => {
  const navigate = useNavigate();
  const location = useSelector(
    (state: { products: { location: any } }) => state.products.location
  );

  const [groceryProducts, setGroceryProducts] = useState<any[]>([]);
  const locations = useLocation();
  const pathSegments = locations.pathname.split("/");
  const routeName = pathSegments[1] || "Unknown";
  const {
    id: ID,
    name: Name,
    parent_category: parent_category,
    subcategories: Subcategories,
    merchantId,
  } = locations.state || {};

  const planet = routeName
    ? routeName === "FoodCategory"
      ? "foodPlanet"
      : routeName === "GroceryCategory"
      ? "groceryPlanet"
      : routeName === "FishCategory"
      ? "fishPlanet"
      : null
    : null;

  const { data: response } = useQuery({
    queryFn: () => {
      if (planet === "fishPlanet") {
        return axiosInstance.post(API_URL.PRODUCT_LIST, {
          index: "0",
          count: "500",
          country: "ALL",
          business_area: planet,
          latitude: location?.latitude,
          longitude: location?.longitude,
        });
      } else {
        return axiosInstance.post(
          API_URL.FOOD_LISTING,
          {
            name: Name,
            latitude: location?.latitude,
            longitude: location?.longitude,
            res: 6,
            planet_id: planet,
          },
          {
            headers: { version: "v4" },
          }
        );
      }
    },
    queryKey: [
      planet === "foodPlanet" ? "getCategories" : "getFishCategories",
      location.latitude,
      location.longitude,
      planet,
    ],
    enabled: !!location?.latitude || !!location?.longitude,
  });
  const handleImageClick = async (subcategory: any) => {
    try {
      const { data } = await axiosInstance.post(
        API_URL.GROCERRY_PRODUCT_LIST,
        JSON.stringify({
          index: "0",
          count: "500",
          country: "ALL",
          business_area: "groceryPlanet",
          category_id: subcategory?.category_id,
          is_parent_category: false,
          merchant_id: merchantId,
        })
      );

      setGroceryProducts(data.content);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const foodlisting = response?.data?.hits?.hits || [];
  const fishListing = response?.data?.content || [];
  const groupedProducts = groupByShop(foodlisting);

  const handleProductClick = (productId: string) => {
    const navigateToDetails =
      planet === "foodPlanet"
        ? `/FoodDetails?id=${productId}`
        : `/GroceryDetails?id=${productId}`;

    navigate(navigateToDetails, {
      state: { productId, planetId: planet },
    });
  };

  return (
    <div className="col-12 d-flex justify-content-center text-start pb-4 pt-5">
      <div className="row width-full-page-product pb-5 relative-div-bg">
        <div className="inner-text-heading">
          <span className="span-with-green">
            {planet === "foodPlanet"
              ? "Restaurants & Dishes"
              : planet === "fishPlanet"
              ? "Fish & Seafood"
              : "Sub Categories"}
          </span>
        </div>

        {/* Subcategories Section */}
        {Subcategories && Subcategories.length > 0 && (
          <div className="subcategories-section pt-3">
            <div className="d-flex flex-wrap">
              {Subcategories.map((subcategory: any) => (
                <div
                  key={subcategory.id}
                  className="p-2 width-in-pixel content-fishcard"
                  onClick={() => handleImageClick(subcategory)}
                >
                  <Card className="card-hghlght card-no-border product-card-cat">
                    <CardBody>
                      <img
                        src={subcategory?.background_image || foodIcon}
                        alt={subcategory?.name}
                        className="img-ratio-card"
                      />
                      <div className="pt-2">
                        <div className="inner-text-heading-two">
                          {subcategory?.name}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="row pt-3">
          {groupedProducts && planet === "foodPlanet"
            ? Object.keys(groupedProducts).map((shopName) => (
                <div className="col-md-4">
                  <Card key={shopName} className="restaurant-category-div mb-4">
                    <CardBody>
                      <div className="bottom-border">
                        <div className="sub-div-headings">{shopName}</div>
                      </div>
                      <div className="d-flex width-main-div-product pt-3 pb-3">
                        {groupedProducts[shopName].map((product: any) => (
                          <div
                            key={product?.productId}
                            className="p-2 width-in-pixel content-fishcard"
                          >
                            <Card className="card-hghlght card-no-border product-card-cat">
                              <CardBody>
                                <img
                                  src={product?.productImages?.[0] || foodIcon}
                                  alt={product?.name}
                                  className="img-ratio-card"
                                />
                                <div className="pt-2">
                                  <div className="inner-text-heading-two">
                                    {product?.name}
                                  </div>
                                  <div className="inner-text-content-three">
                                    Price:{" "}
                                    <span className="inner-text-heading-four">
                                      {product?.price} rs
                                    </span>
                                  </div>
                                  <div className="inner-text-content-four">
                                    <FontAwesomeIcon
                                      icon={faStar}
                                      className="yellow-star"
                                    />
                                    &nbsp; {product.rating} (
                                    {product?.totalReviews} reviews)
                                  </div>
                                  <div className="pt-2">
                                    <button
                                      className="card-button text-white add-to-cart-button"
                                      onClick={() =>
                                        handleProductClick(product?.productId)
                                      }
                                    >
                                      View
                                    </button>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </div>
                        ))}
                      </div>
                    </CardBody>
                  </Card>
                </div>
              ))
            : groceryProducts
            ? groceryProducts?.map((product: any) => (
                <div
                  key={product?.product_id}
                  className="col-md-4 p-2 width-in-pixel content-fishcard"
                >
                  <Card className="card-hghlght card-no-border product-card-cat">
                    <CardBody>
                      <img
                        src={product?.product_details.images[0] || foodIcon}
                        alt={product?.product_name}
                        className="img-ratio-card"
                      />
                      <div className="pt-2">
                        <div className="inner-text-heading-two">
                          {product?.product_name}
                        </div>
                        <div className="inner-text-content-three">
                          Price:{" "}
                          <span className="inner-text-heading-four">
                            {product?.price} rs
                          </span>
                        </div>
                        <div className="inner-text-content-four">
                          <FontAwesomeIcon
                            icon={faStar}
                            className="yellow-star"
                          />
                          &nbsp; {product?.rating} ({product?.total_reviews}{" "}
                          reviews)
                        </div>
                        <div className="pt-2">
                          <button
                            className="card-button text-white add-to-cart-button"
                            onClick={() =>
                              handleProductClick(product?.product_id)
                            }
                          >
                            View
                          </button>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              ))
            : null}
          {planet === "fishPlanet" &&
            fishListing.map((product: any) => (
              <div
                key={product?.product_id}
                className="col-md-4 p-2 width-in-pixel content-fishcard"
              >
                <Card className="card-hghlght card-no-border product-card-cat">
                  <CardBody>
                    <img
                      src={product?.product_details?.images?.[0] || foodIcon}
                      alt={product?.product_name}
                      className="img-ratio-card"
                    />
                    <div className="pt-2">
                      <div className="inner-text-heading-two">
                        {product?.product_name}
                      </div>
                      <div className="inner-text-content-three">
                        Price:{" "}
                        <span className="inner-text-heading-four">
                          {product?.price} rs
                        </span>
                      </div>
                      <div className="inner-text-content-four">
                        <FontAwesomeIcon
                          icon={faStar}
                          className="yellow-star"
                        />
                        &nbsp; {product?.rating} ({product?.total_reviews}{" "}
                        reviews)
                      </div>
                      <div className="pt-2">
                        <button
                          className="card-button text-white add-to-cart-button"
                          onClick={() =>
                            handleProductClick(product?.product_id)
                          }
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default RestaurantCategory;
