import React from "react";
import MapComponent from "../location/location";
import { Button, Modal, ModalBody } from "reactstrap";
import "./styles.css";
interface LocationPopupProps {
  isOpen: boolean;
  onClose: () => void;
  toggle: () => void;
}

const LocationPopup: React.FC<LocationPopupProps> = ({
  isOpen,
  onClose,
  toggle,
}) => {
  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalBody>
        <div>
          <div className="text-end pb-3">
            <Button onClick={onClose} className="  btn btn-transparent">
              X
            </Button>
          </div>
          <MapComponent
            onSelect={(data: any) => {
              console.log("data", data);
            }}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default LocationPopup;
