import React, { useState } from "react";
import "./productdetails.css";
import { Carousel } from "react-responsive-carousel";
import { useQuery } from "react-query";
import axiosInstance from "../../services/axios.instance";
import { API_URL } from "../../services/apiConfig";
import { useLocation } from "react-router-dom";
import { cart_trolly, king_fish, tuna_fish } from "../../assets/images";
import Add from "../../components/fishCard/CartButtons/Add";
import Cart from "../../components/fishCard/CartButtons/Cart";
import { useSelector } from "react-redux";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { log } from "console";

const ProductviewDetails = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const productId = queryParams.get("id");
  // const productId = location.state.product_id;
  const { customerId } = useSelector((state: any) => state.auth);
  const planetId = location.state?.planetId;

  interface TabContent {
    energy?: string;
    total_fat?: string;
    saturated_fat?: string;
    sodium?: string;
    carbohydrates_total?: string;
    sugars?: string;
    average_quantity?: string;
  }

  const [tabContent, setTabContent] = useState<TabContent>({});

  interface Product {
    out_of_stock: boolean;
    product_id: string;
    parent_category_name: string;
    product_details: {
      description: string;
      images: string[] | null;
    };
    inventories: {
      [key: string]: {
        price: number;
        available_stock: number;
      };
    };
    category_name: string;
    weight: number;
    vendor_product_inventories: {
      price: number;
    }[];
  }

  const {
    data: productDetails,
    isLoading,
    isError,
    refetch,
  } = useQuery(["product", productId], async () => {
    try {
      const response = await axiosInstance.get(
        `${API_URL.SINGLE_PRODUCT_LIST}/${productId}`
      );
      return response.data;
    } catch (error) {
      throw new Error("Error fetching product details");
    }
  });

  const handleTabSelect = (key: string | null) => {
    if (
      key === "Nutrition" ||
      key === "Health benefits" ||
      key === "Texture and taste"
    ) {
      setTabContent(productDetails?.product_nutrition?.[0]);
    }
  };
  const { data: recomendedProduct } = useQuery<Product[]>(
    ["recomendedproducts", customerId],
    async () => {
      try {
        const response = await axiosInstance.get<Product[]>(
          `${API_URL.LAST_FIVE_PRODUCT}/${customerId}`
        );
        return response.data;
      } catch (error) {
        throw new Error("Error fetching recomended products");
      }
    }
  );

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error fetching data</div>;
  return (
    <div className="product-details-container col-12 d-flex justify-content-center text-start pb-4 pt-5">
      <div className="row width-full-page pb-5 relative-div-bg">
        <div className="col-12 ">
          <div className="row ">
            <div className="col-md-5 text-center">
              {/* <Carousel
                autoPlay
                interval={3000}
                transitionTime={1000}
                infiniteLoop
                showThumbs={false}
                
               
              > */}
              {productDetails?.product_details?.images &&
                productDetails.product_details.images.map(
                  (
                    image: string | undefined,
                    index: React.Key | null | undefined
                  ) => (
                    <div key={index}>
                      <img
                        src={productDetails?.product_details?.images?.[0]}
                        alt="product"
                        // className="image-carousel-product"
                        className={`image-carousel-product ${
                          productDetails.out_of_stock ? "desaturate" : ""
                        }`}
                        style={{ width: "78%", height: "365px" }}
                      />
                    </div>
                  )
                )}
              {/* </Carousel> */}
            </div>
            <div className="col-md-7 right-product-div">
              <div className="fish-details-text">
                {productDetails?.product_name}
              </div>
              <div className="fish-details-text pt-1">
                {productDetails?.category_name}
              </div>
              <div className="fish-details-text-gm pt-1">
                {productDetails?.weight} g
              </div>
              <div className="fish-details-text pt-1">
                Price :
                <span className="weight-inner-text-heading-four">
                  {" "}
                  ₹ {productDetails?.vendor_product_inventories?.[0]?.price}
                </span>
              </div>

              <div className="col-4 pt-2">
                {productDetails?.out_of_stock ? (
                  <div className="out-of-stock">Out of Stock</div>
                ) : (
                  <Cart product={productDetails} planet={planetId} />
                )}
              </div>
              <div className="fish-desc-text pt-32 mt-4">Description</div>
              <div className="desc-text pt-1">
                {productDetails?.product_details?.description}
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <Tabs
              defaultActiveKey="Nutrition"
              id="uncontrolled-tab-example"
              className="mb-3 ov"
              onSelect={handleTabSelect} // Handle tab select event
            >
              <Tab eventKey="Nutrition" title="Nutrition ( per 100g serving )">
                <p>Energy :{tabContent?.energy}</p>
                <p>Total Fat :{tabContent?.total_fat}</p>
                <p>Saturated Fat:{tabContent?.saturated_fat}</p>
                <p>Sodium :{tabContent?.sodium}</p>
                <p>CarboHydrates :{tabContent?.carbohydrates_total}</p>
                <p>Sugars:{tabContent?.sugars}</p>
                <p>Average Quantity :{tabContent?.average_quantity}</p>
              </Tab>
              <Tab eventKey="Health benefits" title="Health benefits">
                <p>{productDetails?.health_benefits}</p>
              </Tab>
              <Tab eventKey="Texture and taste" title="Texture and taste">
                <p>{productDetails?.taste_and_textures}</p>
              </Tab>
            </Tabs>
          </div>

          <div className="col-12 pt-3">
            <div className="benifit-head-text">Health benefits</div>
            <div className="benifit-sub-text">
              {productDetails?.product_details?.health_benefits}
            </div>
            <div className="benifit-head-text pt-1">Texture and taste</div>
            <div className="benifit-sub-text">
              {productDetails?.product_details?.taste_and_textures}
            </div>
            <div className="benifit-head-text pt-1">Type of fish</div>
            <div className="benifit-sub-text">
              {" "}
              {productDetails?.product_details?.type_of_fish}
            </div>
            <div className="benifit-head-text pt-1">Typical Kerala dishes</div>
            <div className="benifit-sub-text">
              {productDetails?.product_details?.type_of_fish}
            </div>

            <div className="col-md-12 cart-container  pt-3">
              <div className="row pt-2">
                <div className="col-md-12 benifit-sub-text">
                  <img src={cart_trolly} alt="Cart" />
                  <span className="cart-text ps-2">Customers also bought:</span>
                </div>
              </div>
              <div className="row pt-2">
                {recomendedProduct &&
                  recomendedProduct.map((product: any) => (
                    <div className="col-md-3 mb-3" key={product.product_id}>
                      <div className="card">
                        <div className="card-body">
                          {product?.product_details?.images && (
                            <img
                              className="card-img-top"
                              src={product?.product_details?.images?.[0]}
                              alt="Recommended Product"
                            />
                          )}
                          <div className="fish-details-text pt-2">
                            {product?.parent_category_name}
                          </div>
                          <div className="fish-details-text">
                            {product?.category_name} | {product?.weight} g
                          </div>
                          <div className="fish-details-text">
                            Price:{" "}
                            <span className="cart-fish-price">
                              ₹{" "}
                              {product?.vendor_product_inventories?.[0]?.price}
                            </span>
                          </div>
                          <div className="pt-2">
                            <Cart product={product} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductviewDetails;
