import React, { useState } from "react";
import "./CartButtons.css";
import "../FishCard.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  decrementQuantity,
  incrementQuantity,
  productItem,
} from "../../../redux/productList/productListSlice";
import { roundToDecimalPlaces } from "../../../utils/calculation";
import CryptoJS from "crypto-js";
import axiosInstance from "../../../services/axios.instance";
import {
  API_URL,
  guestId,
  sessionId,
  vendorId,
} from "../../../services/apiConfig";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import LocationPopup from "../../locationPopup/locationPop";
import SignInModal from "../../signinmodal/signInModal";
import ErrorModal from "../../ErrorModal/ErrorModal";
import { setEncryptedResponse } from "../../../redux/checkoutSlice/checkoutSlice";

interface CartProps {
  product: productItem;
}

function Cart({ product, planet }: any) {
  const dispatch = useDispatch();
  const productID = product.product_id;
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const encryptedResponses = useSelector(
    (state: any) => state.checkout.encryptedDataResponse
  );
  const [errorMEssage, setErrorMesssage] = useState("");
  const [isErrorStockOpen, setIsErrorStockOpen] = useState(false);

  const cartKey: any = Object.keys(encryptedResponses || {}).find((key) =>
    key.includes("mer")
  );

  const cartItemCount = useSelector((state: any) => {
    try {
      const cartList =
        state.checkout.encryptedDataResponse[cartKey].products ?? [];
      const foundProduct = cartList.find(
        (product: any) => product?.product_id === productID
      );
      return foundProduct?.quantity ?? 0;
    } catch (error) {
      console.error("Error accessing cartItemCount:", error);
      return 0;
    }
  });

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const [CartKey, setkey] = useState(Number);

  interface CartResponse {
    [key: string]: {
      applied_discount_code: string | null;
      shop_information: {
        shop_address: {
          pinCode: string | null;
          businessName: string;
          address: string;
        };
        shop_name: string;
        shop_id: string;
      };
      note: string | null;
      matrix_user_id: string | null;
      products: Array<{
        price: number;
        toppings: any[];
        product_id: string;
        base_product_id: string;
        quantity: number;
      }>;
    };
  }

  const [Cartresponse, setResponse] = useState<CartResponse | null>(null);

  const decryptData = (encryptedData: string, secretKey: string) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  };

  const { customerId } = useSelector((state: any) => state.auth);
  const ex = useSelector((state: any) => state.products);

  const queryClient = useQueryClient();

  const mutation = useMutation(async (encryptDataRequest: any) => {
    try {
      const resp = await axiosInstance.post(
        guestId && sessionId
          ? API_URL.GUEST_USER_ADD_TO_CART
          : API_URL.CART_ITEM_ENCRYPTION,
        encryptDataRequest,
        {
          headers: !guestId
            ? {
                "Content-Type": "text/plain",
                version: "v2",
              }
            : undefined,
        }
      );

      dispatch(setEncryptedResponse(resp.data));

      return resp.data;
    } catch (error: any) {
      dispatch(decrementQuantity(productID));
      if (error.status === 401) {
        setIsErrorModalOpen(true);
        toggleSignInModal();
      } else {
        alert(error.data.message);
      }
    }
  });

  const clearCartMutation = useMutation(async () => {
    try {
      if (guestId && sessionId) {
        await axiosInstance.get(API_URL.GUEST_USER_CLEAR_CART, {
          headers: {
            "Content-Type": "application/json",
          },
        });
      } else {
        await axiosInstance.get(API_URL.CLEAR_CART, {
          headers: {
            "Content-Type": "application/json",
          },
        });
      }

      queryClient.invalidateQueries("cartItemEncryption");
    } catch (error) {
      console.error("Error clearing the cart:", error);
    }
  });

  const handleAddToCart = async () => {
    try {
      const currentCart = encryptedResponses;
      const cartKey: any = Object.keys(currentCart || {}).find((key) =>
        key.includes("mer")
      );

      if (cartKey) {
        const currentCartProducts = encryptedResponses[cartKey]?.products ?? [];
        const hasDifferentMerchant = currentCartProducts.some(
          (item: any) => item.merchant_id !== product.merchant_id
        );

        if (hasDifferentMerchant) {
          await clearCartMutation.mutateAsync();
        }
      }

      let cartData = {
        product_id: product?.product_id,
        base_product_id: product?.base_product_id,
        product_name: product?.product_name,
        product_description: product?.product_details?.description,
        merchant_id: product?.merchant_id,
        quantity: 1,
        item_id: "",
        promotion_id: "",
        price: roundToDecimalPlaces(
          product?.vendor_product_inventories?.[0]?.price
        ),
        toppings: [],
        buy_more_pay_less: {},
        discount_event: {},
        variant: {},
        shop_id: product?.vendor_product_inventories?.[0]?.vendor_id,
        merchand_latitude: product?.merchant_latitude,
        merchand_longitude: product?.merchant_longitude,
        planet_id: planet ? planet : "fishPlanet",
        product_image: product?.product_details?.images?.[0],
      };

      if (guestId && sessionId) {
        const secretKey = guestId;
        const hashedData = JSON.stringify(cartData);
        const encryptDataRequest = CryptoJS.AES.encrypt(
          `${hashedData}`,
          `${secretKey}`
        ).toString();

        dispatch(addToCart(product));

        const GuestPaylod = {
          session_id: sessionId,
          guest_id: guestId,
          cart: encryptDataRequest,
        };
        await mutation.mutateAsync(GuestPaylod);
      } else {
        const secretKey = vendorId;
        const hashedData = JSON.stringify(cartData);
        const encryptDataRequest = CryptoJS.AES.encrypt(
          `${hashedData}`,
          `${secretKey}`
        ).toString();

        dispatch(addToCart(product));
        await mutation.mutateAsync(encryptDataRequest);
      }
    } catch (error) {
      console.error("Error handling add to cart:", error);
    }
  };

  const handleIncrementDecrement = async (type: "increment" | "decrement") => {
    const secretKey: any = guestId ? guestId : vendorId;
    const cartKey: any = Object.keys(encryptedResponses || {}).find((key) =>
      key.includes("mer")
    );

    if (!cartKey) {
      console.error("Cart key not found");
      return;
    }

    const updatedEncryptedResponses = JSON.parse(
      JSON.stringify(encryptedResponses)
    );

    const products = updatedEncryptedResponses[cartKey].products;

    const updatedProducts = products.map((product: any) => {
      if (product.product_id === productID) {
        const currentQuantity = product.quantity || 0;
        const availableStock = product.available_stock || 0;
        const newQuantity =
          type === "increment"
            ? currentQuantity + 1
            : Math.max(0, currentQuantity - 1);

        if (
          type === "increment" &&
          product.is_product_available &&
          currentQuantity >= availableStock
        ) {
          setErrorMesssage("Cannot add more than available stock");
          setIsErrorStockOpen(true);
          return product;
        }

        return {
          ...product,
          quantity: newQuantity,
        };
      }
      return product;
    });

    updatedEncryptedResponses[cartKey].products = updatedProducts;

    dispatch(setEncryptedResponse(updatedEncryptedResponses));
    const hashedData = JSON.stringify(updatedEncryptedResponses);

    const encryptDataRequest = CryptoJS.AES.encrypt(
      hashedData,
      secretKey
    ).toString();

    console.log(encryptDataRequest, "payload");
    console.log(hashedData, "haseddata");

    const mutationToCall =
      type === "increment" || type === "decrement" ? Updatemutation : null;
    try {
      if (mutationToCall) {
        if (guestId && sessionId) {
          const GuestPayload: any = {
            session_id: sessionId,
            guest_id: guestId,
            cart: encryptDataRequest,
          };
          await mutationToCall.mutateAsync({
            encryptDataRequest: GuestPayload,
            type,
          });
        } else {
          await mutationToCall.mutateAsync({ encryptDataRequest, type });
        }
      }
    } catch (error) {
      console.error("Error calling mutation:", error);
    }
  };

  const toggleSignInModal = () => {
    setIsSignInModalOpen(!isSignInModalOpen);
    setIsOpen(false);
  };

  const toggleLocationPopup = () => {
    setIsMapModalOpen(!isMapModalOpen);
    setIsOpen(false);
  };

  const Updatemutation = useMutation(
    async ({
      encryptDataRequest,
      type,
    }: {
      encryptDataRequest: any;
      type: any;
    }) => {
      try {
        if (type === "increment") {
          dispatch(incrementQuantity(productID));
        } else if (type === "decrement") {
          dispatch(decrementQuantity(productID));
        }

        const resp = await axiosInstance.put(
          guestId && sessionId
            ? API_URL.GUEST_USER_UPDATE_CART
            : API_URL.UPDATE_CART,
          encryptDataRequest,
          {
            headers: !guestId
              ? {
                  "Content-Type": "text/plain",
                  version: "v5",
                }
              : undefined,
          }
        );
        return resp.data;
      } catch (error: any) {
        dispatch(decrementQuantity(productID));
        if (error.status === 401) {
          setIsErrorModalOpen(true);
          toggleSignInModal();
        } else {
          alert(error.data.message);
        }
      }
    }
  );

  return (
    <>
      {cartItemCount && cartItemCount > 0 ? (
        <div className=" d-flex after-cart">
          {/* <div className="col"> */}
          <button
            className="cart-counter-button decrement-button"
            onClick={() => handleIncrementDecrement("decrement")}
          >
            -
          </button>
          {/* </div> */}
          <div className="col cart-count text-center">{cartItemCount}</div>

          {/* <div className="col"> */}
          <button
            className="cart-counter-button increment-button"
            onClick={() => handleIncrementDecrement("increment")}
          >
            +
          </button>
          {/* </div> */}
        </div>
      ) : (
        <button
          className="add-to-cart-button"
          onClick={() => handleAddToCart()}
        >
          Add to Cart
        </button>
      )}

      {isSignInModalOpen && !isErrorModalOpen && (
        <SignInModal isOpen={isSignInModalOpen} toggle={toggleSignInModal} />
      )}
      {isMapModalOpen && (
        <LocationPopup
          isOpen={isMapModalOpen}
          onClose={toggleLocationPopup}
          toggle={toggleLocationPopup}
        />
      )}
      {}
      <ErrorModal
        isOpenModal={isErrorModalOpen}
        onCloseModal={() => setIsErrorModalOpen(false)}
        toggleModal={() => setIsErrorModalOpen(isErrorModalOpen)}
        message="Please login"
      ></ErrorModal>
      <ErrorModal
        isOpenModal={isErrorStockOpen}
        onCloseModal={() => setIsErrorStockOpen(false)}
        toggleModal={() => setIsErrorStockOpen(!isErrorStockOpen)}
        message={errorMEssage}
      ></ErrorModal>
    </>
  );
}

export default Cart;
